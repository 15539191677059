import { IResourcePlannerItem } from 'src/apis/types/resourcePlannerAPI';

export const getShimmerRows = (shimmerRowId: string, childCount?: number) => {
  const shimmerRows: IResourcePlannerItem[] = [];
  const loopChildCount = childCount ?? 0;
  for (let i = 0; i < loopChildCount; i += 1) {
    // eslint-disable-next-line prefer-const
    let obj: IResourcePlannerItem = {
      id: shimmerRowId,
      resourceId: `${Math.random() * (100 - 1) + 1}`,
      workItemId: `${Math.random() * (100 - 1) + 1}`,
      name: 'Test',
      type: 'canExpand',
      status: 'NotStarted',
      positiveUnplannedHours: 0,
      negativeUnplannedHours: 0,
      totalBooked: 0,
      totalActualWorkload: 0,
      totalDeviation: 0,
      totalEAC: 0,
      hierarchyType: '',
      total: {
        displayValue: 0,
        heatmapCode: '',
      },
      values: {
        '': {
          displayValue: 'string',
          heatmapCode: 'true',
          editable: true,
        },
      },
      isLoading: true,
    };
    shimmerRows.push(obj);
  }
  return shimmerRows;
};
