import { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import {
  ButtonPrimary,
  ButtonSecondary,
  IconButton,
  Flex,
  Stack,
  Heading,
  ListNumbered,
  ListNumberedItem,
  Text,
  Form,
  FormGroup,
  FormGroupHeading,
  TextInput,
  Textarea,
  RadioButton,
  Accordion,
  FilterSelect,
  ButtonGhost,
  IconButtonGroup,
} from '@timelog/ui-library';

import { useSideMenuStore, useSideMenuDispatch } from 'src/stores/SideMenuStore';

import styles from './ProcessDrawer.module.scss';

const pagesForStepLinks = [
  { value: 'frontpage', label: 'Frontpage' },
  { value: 'register-time', label: 'Register Time' },
  { value: 'employees', label: 'Employees' },
];

export const ProcessDrawer = () => {
  const drawerDispatch = useSideMenuDispatch();
  const drawerState = useSideMenuStore();

  const [stepLinkType, setStepLinkType] = useState('internal');

  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (drawerState?.isSideMenuOpen && drawerRef) {
      setTimeout(() => {
        if (drawerRef.current) {
          drawerRef.current.setAttribute('tabIndex', '-1');
          drawerRef.current.focus();
          drawerRef.current.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && drawerDispatch) {
              drawerDispatch({ type: 'IDLE' });
            }
          });
        }
      }, 100);
    } else if (drawerRef) {
      if (drawerRef.current) {
        drawerRef.current.removeEventListener('keydown', (event) => {
          if (event.key === 'Escape' && drawerDispatch) {
            drawerDispatch({ type: 'IDLE' });
          }
        });
      }
    }
  }, [drawerState?.isSideMenuOpen, drawerDispatch]);

  return (
    <section
      className={cx(styles.ProcessDrawer, {
        [styles.ProcessDrawerIsActive]: drawerState?.isSideMenuOpen,
      })}
      ref={drawerRef}
    >
      <div className={styles.ProcessDrawerInner}>
        <Stack verticalMargin="xLarge">
          <Flex horizontalAlignment="Right">
            {drawerState?.isSideMenuPinned ? (
              <IconButton
                iconName="arrowLeft"
                onClick={() => drawerDispatch && drawerDispatch({ type: 'UNPINNED' })}
                tooltipText="Unpin Drawer"
              />
            ) : (
              <IconButton
                iconName="arrowRight"
                onClick={() => drawerDispatch && drawerDispatch({ type: 'PINNED' })}
                tooltipText="Pin Drawer"
              />
            )}

            <IconButton
              iconName="cross"
              onClick={() => drawerDispatch && drawerDispatch({ type: 'IDLE' })}
              tooltipText="Close Drawer"
            />
          </Flex>

          {drawerState?.sideMenuStage === 'EDIT_PROJECT' ? (
            <>
              <Form>
                <FormGroup as="div">
                  <TextInput label="Process name" name="process-name" />
                  <Textarea label="Process description" name="process-description" />

                  <FormGroup booleanGroup>
                    <FormGroupHeading asLabel>Process type</FormGroupHeading>
                    <Flex gap="large">
                      <RadioButton label="Personal" name="process-type" />
                      <RadioButton label="Company" name="process-type" />
                    </Flex>
                  </FormGroup>
                </FormGroup>

                <Flex>
                  <ButtonPrimary
                    onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
                  >
                    Save
                  </ButtonPrimary>
                  <ButtonSecondary
                    onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
                  >
                    Cancel
                  </ButtonSecondary>
                </Flex>
              </Form>

              <Accordion collapsible multiple>
                <Accordion.Item>
                  <Flex horizontalAlignment="Justify">
                    <Flex.Item fillRest>
                      <Accordion.Trigger level={2}>Step 1</Accordion.Trigger>
                    </Flex.Item>

                    <IconButton iconName="delete" tooltipText="Delete step" />
                  </Flex>
                  <Accordion.Panel>
                    <Form>
                      <FormGroup as="div">
                        <TextInput label="Step name" name="step-name" />
                        <Textarea label="Step description" name="step-description" />

                        <FormGroup booleanGroup>
                          <FormGroupHeading asLabel>Step type</FormGroupHeading>
                          <Flex gap="large">
                            <RadioButton
                              label="Internal"
                              name="step-type"
                              checked={stepLinkType === 'internal'}
                              value="internal"
                              onChange={(e) => setStepLinkType(e.currentTarget.value)}
                            />
                            <RadioButton
                              label="External"
                              name="step-type"
                              checked={stepLinkType === 'external'}
                              value="external"
                              onChange={(e) => setStepLinkType(e.currentTarget.value)}
                            />
                          </Flex>

                          {stepLinkType === 'internal' && (
                            <TextInput label="Step url" type="url" name="step-name" />
                          )}

                          {stepLinkType === 'external' && (
                            <FilterSelect options={pagesForStepLinks} label="" />
                          )}
                        </FormGroup>
                      </FormGroup>

                      <Flex>
                        <ButtonPrimary
                          onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
                        >
                          Add
                        </ButtonPrimary>
                        <ButtonSecondary
                          onClick={() => drawerDispatch && drawerDispatch({ type: 'NEW_PROJECT' })}
                        >
                          Cancel
                        </ButtonSecondary>
                      </Flex>
                    </Form>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item>
                  <Flex horizontalAlignment="Justify">
                    <Flex.Item fillRest>
                      <Accordion.Trigger level={2}>Step 2</Accordion.Trigger>
                    </Flex.Item>

                    <IconButton iconName="delete" tooltipText="Delete step" />
                  </Flex>
                  <Accordion.Panel>Step 1 Panel</Accordion.Panel>
                </Accordion.Item>
              </Accordion>

              <ButtonPrimary>Add step</ButtonPrimary>
            </>
          ) : (
            <Stack>
              <Flex horizontalAlignment="Justify">
                <Heading level={1} as={2} bold>
                  Process name
                </Heading>

                <IconButtonGroup>
                  <IconButton
                    iconName="edit"
                    onClick={() => drawerDispatch && drawerDispatch({ type: 'EDIT_PROJECT' })}
                    size="small"
                    tooltipText="Edit process"
                  />

                  <IconButton
                    iconName="delete"
                    onClick={() => drawerDispatch && drawerDispatch({ type: 'IDLE' })}
                    size="small"
                    tooltipText="Delete process"
                  />
                </IconButtonGroup>
              </Flex>

              <Text>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel aut.</Text>

              <ListNumbered>
                <ListNumberedItem>
                  <ButtonGhost>
                    <Text as="span" bold>
                      Step no. 1
                    </Text>
                  </ButtonGhost>
                  <Text>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur ratione
                    veritatis tempora ad culpa neque eligendi magnam! Pariatur modi, atque, numquam
                    tempora repellendus dignissimos at et tenetur nesciunt nam officia.
                  </Text>
                </ListNumberedItem>

                <ListNumberedItem>
                  <ButtonGhost>
                    <Text as="span" bold>
                      Step no. 2
                    </Text>
                  </ButtonGhost>
                  <Text>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur ratione
                    veritatis tempora ad culpa neque eligendi magnam! Pariatur modi, atque, numquam
                    tempora repellendus dignissimos at et tenetur nesciunt nam officia.
                  </Text>
                </ListNumberedItem>
              </ListNumbered>

              <ButtonSecondary onClick={() => drawerDispatch && drawerDispatch({ type: 'IDLE' })}>
                Stop process
              </ButtonSecondary>
            </Stack>
          )}
        </Stack>
      </div>
    </section>
  );
};
