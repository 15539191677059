import { useTranslation } from 'react-i18next';
import { useGetBackToTimeLogPage } from 'src/stores/PageStore';

import { Heading, TextLink } from '@timelog/ui-library';
import MainSearchStoreProvider from 'src/stores/MainSearchStore';
import FeatureControl from 'src/components/FeatureControl';
import { Navigation } from './components/Navigation';
import { MainSearch } from './components/MainSearch';
import TimeTrackerButton from './components/TimeTrackerButton';
import HelpButton from '../HelpButton';
import { HeaderBase } from './HeaderBase';

import styles from './Header.module.scss';
import SignUpButton from './components/SignUpButton';

export const HeaderWithNavigation = () => (
  <HeaderBase
    primaryArea={<Navigation />}
    optionsArea={
      <>
        <SignUpButton />
        <FeatureControl
          indexControlName="TimeTracker"
          userFeatureKey="useStopwatchTimeTracking"
          pageIdentifierKey="TimeTrackingBase"
        >
          <TimeTrackerButton />
        </FeatureControl>
        <FeatureControl indexControlName="Search">
          <MainSearchStoreProvider>
            <MainSearch />
          </MainSearchStoreProvider>
        </FeatureControl>
        <FeatureControl indexControlName="Users">
          <HelpButton size="large" iconSize="large" />
        </FeatureControl>
      </>
    }
    data-automation-id="HeaderWithNavigation"
  />
);

interface HeaderWithHeadingProps {
  heading: string;
  isHelpVisible?: boolean;
}

export const HeaderWithHeading = ({ heading, isHelpVisible = true }: HeaderWithHeadingProps) => {
  const { t } = useTranslation('header');
  const backToTimeLogUrl = useGetBackToTimeLogPage();
  return (
    <HeaderBase
      primaryArea={
        <div className={styles.Heading}>
          <Heading level={1} as={2} medium data-automation-id="HeaderWithHeading">
            {heading}
          </Heading>
        </div>
      }
      optionsArea={
        <>
          <TextLink
            href={backToTimeLogUrl}
            className={styles.BackLink}
            data-automation-id="HeaderGoBack"
            useTextColor
          >
            {t('SettingsPagesBackToTimeLogLink')}
          </TextLink>
          {isHelpVisible ? (
            <FeatureControl indexControlName="Users">
              <HelpButton size="large" iconSize="large" />
            </FeatureControl>
          ) : null}
        </>
      }
    />
  );
};
