import { FC, useState } from 'react';
import { Moment } from 'moment';
import { DateRangePicker, DateRangePickerShape, FocusedInputShape } from 'react-dates';
import { FormField, FormFieldLabel, FormFieldControl } from 'components/FormField';
import { Icon } from '../../Icon';
import { isDateOutsideRange, TDayLimit } from '../utils';
import 'react-dates/initialize';

// Use require to prevent postcss from scoping 3rd party css
require('react-dates/lib/css/_datepicker.css');

type DatePickerRangeProps = DateRangePickerShape & {
  startDateId: string;
  endDateId: string;
  startLabel: string;
  endLabel: string;
  hiddenLabel?: boolean;
  showIcon?: boolean;
  dateLimit?: TDayLimit;
};

interface IHandleDatesChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

export const SmartDatePickerRange: FC<DatePickerRangeProps> = ({
  startLabel,
  startDate,
  startDateId,
  endLabel,
  endDate,
  endDateId,
  focusedInput,
  hiddenLabel,
  showIcon,
  dateLimit,
  isOutsideRange,
  onDatesChange,
  onFocusChange,
  ...props
}) => {
  const [initStartDate, setStartDate] = useState<Moment | null>(startDate);
  const [initEndDate, setEndDate] = useState<Moment | null>(endDate);
  const [initFocusedInput, setFocusedInput] = useState<FocusedInputShape | null>(focusedInput);
  const handleDatesChange = ({
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  }: IHandleDatesChange) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    onDatesChange({ startDate: selectedStartDate, endDate: selectedEndDate });
  };
  const handleFocusChange = (focused: FocusedInputShape | null) => {
    setFocusedInput(focused);
    onFocusChange(focused);
  };
  return (
    <FormField>
      <FormFieldLabel hiddenLabel={hiddenLabel}>
        <label htmlFor={startDateId}>{startLabel}</label>
      </FormFieldLabel>

      <FormFieldControl inputWidth="auto">
        <DateRangePicker
          startDate={initStartDate}
          startDateId={startDateId}
          endDate={initEndDate}
          endDateId={endDateId}
          focusedInput={initFocusedInput}
          customInputIcon={showIcon ? <Icon iconName="calendar" /> : null}
          inputIconPosition="after"
          startDatePlaceholderText={startLabel}
          endDatePlaceholderText={endLabel}
          onDatesChange={handleDatesChange}
          onFocusChange={handleFocusChange}
          isOutsideRange={(day) =>
            isDateOutsideRange({ day, dayLimitType: dateLimit, isOutsideRange })
          }
          {...props}
        />
      </FormFieldControl>
    </FormField>
  );
};
