export default {
  Properties: {
    Version: '6.2.100',
    ChangeSet: '20211214044513-merge-1d2c0f208ac9c04022c54555dd222d90d4eba7a7',
    ServerTimeZoneId: 'Singapore Standard Time',
    ProductType: 'Invoicing',
    CompanyName: 'TimeLog A/S',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2',
      Rel: 'self',
    },
    {
      Href: 'http://localhost/tlp/api/v2/user-preview-features',
      Rel: 'UserPreviewFeatures',
      Actions: [
        {
          Name: 'UserPreviewFeaturesGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/user-preview-features/',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/time-tracker',
      Rel: 'TimeTracker',
      Actions: [
        {
          Name: 'StopTimeTrackerV2',
          Method: 'POST',
          Href: 'http://localhost/tlp/api/v2/time-tracker/stop/{timeTrackerId}',
        },
        {
          Name: 'GetActiveTimeTrackerV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/time-tracker/active',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/menus',
      Rel: 'Menus',
      Actions: [
        {
          Name: 'CreateMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/create',
        },
        {
          Name: 'MainMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/main',
        },
        {
          Name: 'PageSettingsGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/page-settings',
        },
        {
          Name: 'PersonalSettingsMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/personal-settings',
        },
        {
          Name: 'SystemAdministrationMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/system-administration',
        },
      ],
    },
  ],
};
