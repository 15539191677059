import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { IWidget } from './WidgetType';
import style from './Widget.module.scss';
import WidgetDashboardProvider, { useWidgetList } from './store/WidgetDashboardStore';

interface IWidgetDashboard extends HTMLAttributes<HTMLDivElement> {
  widgetList: Array<IWidget>;
}

const WidgetDashboard = ({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) => {
  const widgetList = useWidgetList();
  return (
    <div className={classNames(style.widgetDashboard, className)} {...restProps}>
      {widgetList.map(({ key, size, children, isVisible = true }) => (
        <div
          key={key}
          data-automation-id={`widget${size}by1${key}`}
          className={classNames(style?.[`widget${size}by1`], { [style.widgetHide]: !isVisible })}
        >
          {children}
        </div>
      ))}
    </div>
  );
};

export default ({ widgetList, ...restProps }: IWidgetDashboard) => (
  <WidgetDashboardProvider widgetList={widgetList}>
    <WidgetDashboard {...restProps} />
  </WidgetDashboardProvider>
);
