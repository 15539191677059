import { ButtonPrimary } from '@timelog/ui-library';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetRPSignalRConfig,
} from 'src/apis/resourcePlannerAPI';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ToastItem } from 'src/components/ToastItem';
import { signalRConnectionIdKey } from '../sessionStorageKeys';

export const useSignalRConnection = () => {
  const qc = useQueryClient();
  const { t } = useTranslation('resourcePlanner');

  const { tenantId, serviceUrl } = useGetRPSignalRConfig();

  const reloadResourcePlanner = useCallback(() => {
    qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
    qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
    toast.dismiss('reloadRPToast');
  }, [qc]);

  useEffect(() => {
    (async () => {
      try {
        const hubConnection = new HubConnectionBuilder()
          .withUrl(serviceUrl)
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        // Starts the SignalR connection
        await hubConnection.start().catch((error) => {
          // eslint-disable-next-line
          console.log(error);

          toast.update('rpIsLoadingToast', {
            render: <ToastItem message="Error in connection. Try again" />,
            type: toast.TYPE.ERROR,
          });
        });

        // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
        if (hubConnection.connectionId) {
          sessionStorage.setItem(signalRConnectionIdKey, hubConnection.connectionId);
          hubConnection.invoke('SubscribeToTenantId', tenantId);
        }

        hubConnection.on('ReloadPlanWithMessage', () => {
          toast.info(
            <ToastItem
              message={t('NewChangesDetected')}
              actions={
                <ButtonPrimary
                  size="tiny"
                  onClick={reloadResourcePlanner}
                  data-automation-id="Reload"
                >
                  {t('Reload')}
                </ButtonPrimary>
              }
            />,
            { toastId: 'reloadRPToast' },
          );
        });

        hubConnection.on('ReloadPlan', () => {
          qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
          qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
          qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
        });
      } catch {
        // catch error
      }
    })();
  }, [serviceUrl, tenantId, qc, reloadResourcePlanner, t]);
};
