import { useEffect } from 'react';
import { Text, Stack, Icon, useWidgetDashboardDispatch } from '@timelog/ui-library';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { translationAnyText } from 'src/utils/translation';
import WidgetHeader from 'src/components/WidgetHeader';
import styles from './WidgetWithoutChart.module.scss';
import WidgetWithoutChartShimmer from '../WidgetWithoutChartShimmer';
import WidgetError from '../WidgetError';
import { IValueText, IWidgetWithoutChart } from '../../HomeType';

type IconNameResult = 'trendingUp' | 'trendingDown';

const getIconName = ({
  trending,
  trendingDirection,
}: Pick<IValueText, 'trending' | 'trendingDirection'>): IconNameResult => {
  if (trendingDirection) {
    return trendingDirection === 'up' ? 'trendingUp' : 'trendingDown';
  }
  return trending === 'Yes' ? 'trendingUp' : 'trendingDown';
};

export const getIconNameForTest = getIconName;

interface IReplaceAbbrTag extends Pick<IValueText, 'abbrText' | 'abbrTitle'> {
  children: string;
}

const ReplaceAbbrTag = ({ children, abbrText, abbrTitle }: IReplaceAbbrTag) => {
  if (children && abbrText && abbrTitle) {
    const textPart = children.split(abbrText);

    return (
      <span data-automation-id={`abbrSpan${abbrText}`}>
        {textPart?.map(
          (text, textIndex) =>
            text?.replaceAll(' ', '') && (
              <span
                data-automation-id={`abbrSpanSubPart${textIndex}`}
                key={`abbrSpan${text?.replaceAll(' ', '')}`}
              >
                {text}{' '}
                {textIndex < textPart.length - 1 && (
                  <abbr title={abbrTitle} data-automation-id={`abbrTag${textIndex}`}>
                    {abbrText}
                  </abbr>
                )}
              </span>
            ),
        )}
      </span>
    );
  }
  return <span data-automation-id="spanWithoutAbbr">{children}</span>;
};

export const ReplaceAbbrTagForText = ReplaceAbbrTag;

const ValueText = ({
  trending,
  value,
  translationKey,
  trendingDirection,
  abbrText,
  abbrTitle,
  ...restProps
}: IValueText) => {
  const { t } = useTranslation('frontpage');
  const translationText = translationAnyText(t, translationKey).replace('{{value}}', value);

  if (trending === 'Yes' || trending === 'No') {
    return (
      <dd
        className={classNames(styles.label, {
          [styles.trendingUp]: trending === 'Yes',
          [styles.trendingDown]: trending === 'No',
        })}
        {...restProps}
      >
        <ReplaceAbbrTag abbrText={abbrText} abbrTitle={abbrTitle}>
          {translationText}
        </ReplaceAbbrTag>
        <Icon iconName={getIconName({ trending, trendingDirection })} size="large" />
      </dd>
    );
  }
  return (
    <Text as="dd" bold size="subHeading" {...restProps}>
      <ReplaceAbbrTag abbrText={abbrText} abbrTitle={abbrTitle}>
        {translationText}
      </ReplaceAbbrTag>
    </Text>
  );
};

export const ValueTextForTest = ValueText;

export default ({
  queryKey,
  informationList,
  tips,
  relId,
  alwaysVisible,
  isLoading = false,
  isError = false,
  actionName,
}: IWidgetWithoutChart) => {
  const { isValidIndexControlName, isLoading: isIndexLoading } = useIsValidIndexControlName(
    'WidgetData',
    actionName,
  );
  const dispatch = useWidgetDashboardDispatch();
  const { t } = useTranslation('frontpage');

  useEffect(() => {
    if (dispatch && !isValidIndexControlName && !isIndexLoading && !alwaysVisible) {
      dispatch({ type: 'HIDE_WIDGET', payload: { key: relId } });
    }
  }, [relId, dispatch, isValidIndexControlName, isIndexLoading, alwaysVisible]);

  if (isLoading || isIndexLoading) {
    return <WidgetWithoutChartShimmer />;
  }
  if (isError) {
    return <WidgetError queryKey={queryKey} />;
  }

  return (
    <div className={styles.widgetWrapper}>
      <Stack>
        <WidgetHeader widgetKey={relId} heading={t(`WidgetTitle${relId}`)} />

        <Stack as="dl">
          {informationList.map(
            ({ value, trending = null, trendingDirection, key, abbrTitle, abbrText }) => (
              <div className={styles.defWrapper} key={`stack${key}`}>
                {translationAnyText(t, `Widget${relId}${key}Name`) !==
                  `Widget${relId}${key}Name` && (
                  <Text as="dt" size="tips" data-automation-id={`Widget${relId}${key}Name`}>
                    {translationAnyText(t, `Widget${relId}${key}Name`)}
                  </Text>
                )}
                <ValueText
                  value={value}
                  trending={trending}
                  trendingDirection={trendingDirection}
                  key={key}
                  data-automation-id={`Widget${relId}${key}Value`}
                  translationKey={`Widget${relId}${key}Value`}
                  abbrText={abbrText}
                  abbrTitle={abbrTitle}
                />
              </div>
            ),
          )}
        </Stack>
      </Stack>
      <div className={styles.tips}>{tips}</div>
    </div>
  );
};
