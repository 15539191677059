import { Column } from '@tanstack/react-table';
import { TextInput, Flex, useGetLocale } from '@timelog/ui-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringToPascal } from 'src/utils/string';
import { formatTime, validateTimeInput } from 'src/utils/time';
import { RTRow, RPRow } from '../../types/resourcePlanner';
import styles from './EditableCell.module.scss';

interface EditableCellProps {
  row: RTRow;
  column: Column<RPRow>;
  updateMyData?: (row: RTRow, column: Column<RPRow>, value: string) => void;
  editable: boolean;
  unitType: string;
  reportingType: string;
}

const convertNumber = (num: string, locale: string): number => {
  const { format } = new Intl.NumberFormat(locale);
  const decimalSign = /^0(.)1$/.exec(format(0.1))?.[1] || '0';
  return +num.replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '').replace(decimalSign, '.');
};

const EditableCell = ({
  row,
  column,
  updateMyData,
  editable,
  unitType,
  reportingType,
}: EditableCellProps) => {
  if (!column.id) {
    throw new Error('Invalid column');
  }

  const { t } = useTranslation('resourcePlanner');
  const siteLocale = useGetLocale();
  const periodColumnIdSubstring = column.id.substring(column.id.indexOf('_') + 1, column.id.length);

  const actualValue = row?.original?.values?.[periodColumnIdSubstring]?.displayValue || '0';
  const actualValueInt = parseFloat(actualValue);
  const time = formatTime(actualValue, siteLocale);
  const [value, setValue] = useState<string>(time);
  const [validationError, setValidationError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const emptyString: string = '';

  useEffect(() => {
    if (actualValueInt >= 0) {
      setValue(
        actualValueInt === 0
          ? emptyString
          : actualValueInt.toLocaleString(siteLocale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
      );
    }
  }, [actualValueInt, siteLocale]);

  const isUnitTypePercentage = unitType && unitType === 'percentages';
  if (!editable) {
    if (reportingType === 'utilization') {
      if (isUnitTypePercentage) {
        return (
          <Flex horizontalAlignment="right">
            <span className={styles.inputCellWithoutInput}>
              {actualValue === undefined || actualValue === '0'
                ? emptyString
                : `${formatTime(actualValue, siteLocale)}`}
            </span>
            <span>{actualValue === undefined || actualValue === '0' ? emptyString : '%'}</span>
          </Flex>
        );
      }
      if (!isUnitTypePercentage && actualValue === '0') {
        return <span className={styles.inputCellWithoutInput}>{emptyString}</span>;
      }
    }

    if (reportingType === 'availability') {
      if (isUnitTypePercentage) {
        return (
          <Flex horizontalAlignment="right">
            <span className={styles.inputCellWithoutInput}>
              {actualValue === undefined ? emptyString : `${formatTime(actualValue, siteLocale)}`}
            </span>
            <span>{actualValue === undefined ? emptyString : '%'}</span>
          </Flex>
        );
      }

      if (!isUnitTypePercentage && actualValue === '0') {
        return (
          <span className={styles.inputCellWithoutInput}>{`${formatTime('0', siteLocale)}`}</span>
        );
      }
    }
    return (
      <span className={styles.inputCellWithoutInput}>
        {actualValue === undefined ? emptyString : time}
      </span>
    );
  }

  const onFocus = () => {
    setIsSaved(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(e.target.value);
    setIsUpdated(true);
  };

  const onBlur = () => {
    const currentValue = value === emptyString ? '0' : value;
    const { status, value: outputValue } = validateTimeInput(
      currentValue,
      unitType,
      t('UseDecimalFormatValidationFeedback'),
    );

    if (isUpdated) {
      if (status === 'error') {
        setValidationError(outputValue);
        return;
      }

      const currentValueInt = convertNumber(currentValue, siteLocale);
      const formattedCurrentValue = currentValueInt.toLocaleString(siteLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setIsSaved(true);
      setIsUpdated(false);
      setValue(currentValueInt === 0 ? emptyString : formattedCurrentValue);

      if (!Number.isNaN(currentValueInt) && currentValueInt !== actualValueInt) {
        setValidationError('');
        if (updateMyData) {
          updateMyData(row, column, currentValueInt.toString());
        }
      }
    }
  };

  return value !== undefined ? (
    <Flex horizontalAlignment="right">
      <TextInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ariaLabelledby={`firstColumnRow${row.id} tableHeaderCell${stringToPascal(
          periodColumnIdSubstring,
        )}`}
        name=""
        rightAlign
        hiddenLabel
        inputSize="small"
        inputWidth="xSmall"
        validationMsg={validationError}
        status={validationError && 'error'}
        usePopOutValidation
        saved={isSaved}
      />
      {isUnitTypePercentage && <span>%</span>}
    </Flex>
  ) : null;
};

export default EditableCell;
