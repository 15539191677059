export default {
  Properties: {
    Version: '6.2.100',
    ChangeSet: '20211214044513-merge-1d2c0f208ac9c04022c54555dd222d90d4eba7a7',
    ServerTimeZoneId: 'Singapore Standard Time',
    ProductType: 'Invoicing',
    ShortName: 'app8.timelog.com/localarena',
    Edition: 'Business',
    CustomerPortalUrl: null,
    CompanyName: 'TimeLog A/S',
    SiteLcid: 1030,
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2',
      Rel: 'self',
      Actions: undefined,
    },
    {
      Href: 'http://localhost/tlp/api/v2/user-preview-features',
      Rel: 'UserPreviewFeatures',
      Actions: [
        {
          Name: 'UserPreviewFeaturesGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/user-preview-features/',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/search',
      Rel: 'Search',
      Actions: [
        {
          Name: 'SearchV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/search/',
        },
        {
          Name: 'SearchCategoryV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/search/categories',
        },
        {
          Name: 'SearchCategoryUpdateV2',
          Method: 'PUT',
          Href: 'http://localhost/tlp/api/v2/search/categories',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/time-tracker',
      Rel: 'TimeTracker',
      Actions: [
        {
          Name: 'StopTimeTrackerV2',
          Method: 'POST',
          Href: 'http://localhost/tlp/api/v2/time-tracker/stop/{timeTrackerId}',
        },
        {
          Name: 'GetActiveTimeTrackerV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/time-tracker/active',
        },
      ],
    },
    {
      Href: 'http://localhost/timelog/api/v2/milestones',
      Rel: 'Milestones',
      Actions: [
        {
          Name: 'GetAllUserIncompleteMilestonesV2',
          Method: 'GET',
          Href: 'http://localhost/timelog/api/v2/milestones/incomplete',
        },
        {
          Name: 'UpdateMilestoneStatusV2',
          Method: 'PUT',
          Href: 'http://localhost/timelog/api/v2/milestones/{id}/status',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/feed',
      Rel: 'Feed',
      Actions: [
        {
          Name: 'GetRSSFeedV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/feed/',
        },
      ],
    },
    {
      Href: 'http://localhost/timelog/api/v2/widget-data',
      Rel: 'WidgetData',
      Actions: [
        {
          Name: 'GetTimeRegistrationAccuracyInPeriodV2',
          Method: 'GET',
          Href: 'http://localhost/timelog/api/v2/widget-data/time-registration-accuracy',
        },
        {
          Name: 'GetWidgetDataExternalPercentageV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/widget-data/external-percentage',
        },
        {
          Name: 'GetWidgetDataBillablePercentageV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/widget-data/external-percentage',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/bookmarks',
      Rel: 'Bookmarks',
      Actions: [
        {
          Name: 'BookmarksGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/bookmarks/',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/menus',
      Rel: 'Menus',
      Actions: [
        {
          Name: 'CreateMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/create',
        },
        {
          Name: 'MainMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/main',
        },
        {
          Name: 'PageSettingsGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/page-settings',
        },
        {
          Name: 'PersonalSettingsMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/personal-settings',
        },
        {
          Name: 'SystemAdministrationMenuGetAllV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/menus/system-administration',
        },
      ],
    },
    {
      Href: 'http://localhost/tlp/api/v2/users',
      Rel: 'Users',
      Actions: [
        {
          Name: 'UsersGetCurrentUserV2',
          Method: 'GET',
          Href: 'http://localhost/tlp/api/v2/users/me',
        },
      ],
    },
  ],
};
