import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Flex, Shimmer, Table, TCellIndentation } from '@timelog/ui-library';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { IResourcePlannerPeriodValueString } from 'src/apis/types/resourcePlannerAPI';
import { stringToPascal } from 'src/utils/string';
import { RPProjectViewInitialExpandedRowsStateKey } from '../../localStorageKeys';
import { NewRTCell, NewRTRow, RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import NameColumnRenderer from '../NameColumnRenderer';
import ResourceBar from '../ResourceBar';
import MemoizedRenderBodyCellPeriodColumn from '../MemoizedRenderBodyCellPeriodColumn/MemoizedRenderBodyCellPeriodColumn';
import MemoizedTableColGroupColumn from '../MemoizedTableColGroupColumn/MemoizedTableColGroupColumn';
import ToggleRowsButton from '../ToggleRowsButton';
import MemoizedRenderBodyCellColumn from '../MemoizedRenderBodyCellColumn';
import RenderBodyFirstColumn from './RenderBodyFirstColumn';
import { getNotPlannedFontColor } from '../../helper/getNotPlannedFontColor';
import { getExpandedTableRowIds } from '../../helper/expandedRow';

interface ResourceTableGroupedByProjectTableProps {
  columns: ColumnDef<RPRow>[];
  data: RPRow[];
  onCellValueChange: (row: RTRow, column: RTColumn, value: string) => void;
  unitType: string;
  reportingType: string;
  selectedViewOptions: {
    [key: string]: string;
  };
  handleOnToggleRow: (row: RTRow) => void;
  setExpandedRows: Function;
}

const ResourceTableGroupedByProjectTable = ({
  columns,
  data,
  onCellValueChange,
  unitType,
  reportingType,
  selectedViewOptions,
  handleOnToggleRow,
  setExpandedRows,
}: ResourceTableGroupedByProjectTableProps) => {
  const [expanded, setExpanded] = useState<ExpandedState>(() =>
    getExpandedTableRowIds(RPProjectViewInitialExpandedRowsStateKey),
  );

  const getShouldDisableExpandAll = () => {
    if (data && data.length >= 100) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setExpanded(getExpandedTableRowIds(RPProjectViewInitialExpandedRowsStateKey));
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    meta: {
      updateData: onCellValueChange,
    },
    manualExpanding: false,
    getSubRows: (row) => (row?.children && row?.children?.length > 0 ? row.children : []),
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row: RTRow) => !!row.original.canExpand,
  });

  const tableRows = table.getRowModel().rows;

  const memoizedTableRows = useMemo(
    () =>
      tableRows.map((row) => {
        const { startsAt, endsAt } = row.original;

        const newRow: NewRTRow = {
          ...row,
          allCells: row.getVisibleCells().map((cell) => ({
            ...cell,
            colSpan: false,
            ignoreRender: false,
          })),
          colSpanCounter: 0,
        };

        if (
          newRow.original.hierarchyType === 'workitem' &&
          (reportingType === 'availability' || unitType === 'percentages') &&
          startsAt &&
          endsAt
        ) {
          const startsAtDate = DateTime.fromISO(startsAt);
          const endsAtDate = DateTime.fromISO(endsAt);

          newRow.allCells = row.getVisibleCells().map((cell) => {
            const updatedCell: NewRTCell = {
              ...cell,
              colSpan: false,
              ignoreRender: false,
            };

            const columnIsPeriod = cell.column.columnDef.meta?.isPeriod;

            if (columnIsPeriod) {
              const columnStartsAtDate = DateTime.fromISO(cell.column.columnDef.meta?.startsAt!);
              const columnEndssAtDate = DateTime.fromISO(cell.column.columnDef.meta?.endsAt!);
              if (
                (columnStartsAtDate >= startsAtDate && columnStartsAtDate <= endsAtDate) ||
                (columnEndssAtDate >= startsAtDate && columnEndssAtDate <= endsAtDate) ||
                (columnStartsAtDate <= startsAtDate && columnEndssAtDate >= endsAtDate)
              ) {
                if (newRow.colSpanCounter === 0) {
                  newRow.colSpanCounter = 1;
                  updatedCell.colSpan = true;
                } else {
                  newRow.colSpanCounter += 1;
                  updatedCell.ignoreRender = true;
                }
              }
            }

            return updatedCell;
          });
        }
        return newRow;
      }),
    [tableRows, unitType, reportingType],
  );

  return (
    <Table
      stickyFirstColumn
      stickyLastColumn
      compact
      tableHover
      data-automation-id="ResourceViewTable"
    >
      <Table.ColGroup>
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <MemoizedTableColGroupColumn key={`colGroup_col_${header.column.columnDef.id}`} />
            )),
          )}
      </Table.ColGroup>

      <Table.Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={`headerGroup_row_${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Table.Header
                key={`header_${header.column.columnDef.id}`}
                alignment={header.column.columnDef.meta?.alignment}
                id={`tableHeaderCell${stringToPascal(header.column.id)}`}
              >
                {header.column.columnDef.id === 'name' ? (
                  <Flex key={header.id}>
                    <ToggleRowsButton
                      rows={table.getRowModel().flatRows}
                      setExpandedRows={setExpandedRows}
                      viewStorageKey={RPProjectViewInitialExpandedRowsStateKey}
                      handleOnToggleRow={handleOnToggleRow}
                      disableExpandAll={getShouldDisableExpandAll()}
                    />
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                ) : (
                  <span title={header.column.columnDef.meta?.title}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                )}
              </Table.Header>
            ))}
          </Table.Row>
        ))}
      </Table.Head>

      <Table.Body>
        {memoizedTableRows.map((row) => (
          <Table.Row key={`body_row_${row.id}`}>
            {row.allCells.map((cell) => {
              if (cell.ignoreRender) {
                return null;
              }

              if (row.original.isLoading) {
                return (
                  <Table.Cell
                    alignment={cell.column.columnDef.meta?.alignment}
                    type={cell.column.columnDef.meta?.type}
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    indent={
                      cell.column.columnDef.id === 'name'
                        ? (row.depth as TCellIndentation)
                        : (0 as TCellIndentation)
                    }
                    valign="bottom"
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                  >
                    <Shimmer height="quarter" />
                  </Table.Cell>
                );
              }

              if (cell.column.columnDef.id === 'name') {
                return (
                  <RenderBodyFirstColumn
                    rowId={row.id}
                    depth={row.depth as TCellIndentation}
                    alignment="left"
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                  >
                    <NameColumnRenderer
                      row={row}
                      value={cell.getValue<string>()}
                      handleOnToggleRow={handleOnToggleRow}
                    />
                  </RenderBodyFirstColumn>
                );
              }

              if (cell.colSpan) {
                return (
                  <Table.Cell
                    colSpan={row.colSpanCounter}
                    key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                  >
                    <ResourceBar depth={row.depth as number} />
                  </Table.Cell>
                );
              }

              if (
                (row.getCanExpand() &&
                  cell.column.columnDef.meta?.editable &&
                  (reportingType === 'availability' || unitType === 'percentages')) ||
                (row.original.resourceType === 'Unknown' &&
                  (reportingType === 'availability' || unitType === 'percentages') &&
                  row.subRows.length < 1 &&
                  cell.column.columnDef.meta?.editable)
              ) {
                return (
                  <Table.Cell
                    key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                  />
                );
              }

              if (cell.column.columnDef.id?.startsWith('periodCol_')) {
                const periodColumnIdSubString = cell.column.columnDef.id.substring(
                  cell.column.columnDef.id.indexOf('_') + 1,
                  cell.column.columnDef.id.length,
                );

                const periodCellData: IResourcePlannerPeriodValueString = cell.column.columnDef.meta
                  ?.editable
                  ? row.original.values[periodColumnIdSubString]
                  : (cell.getValue() as IResourcePlannerPeriodValueString);

                const isCellEditable =
                  (selectedViewOptions['reporting-types'] !== 'availability' &&
                    row.original.editable &&
                    cell.column.columnDef?.meta?.editable &&
                    periodCellData?.editable) ||
                  false;

                return (
                  <MemoizedRenderBodyCellPeriodColumn
                    alignment={cell.column.columnDef.meta?.alignment}
                    editable={cell.column.columnDef.meta?.editable}
                    type={cell.column.columnDef.meta?.type}
                    heatmapCode={periodCellData?.heatmapCode}
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    row={row}
                    column={cell.column}
                    handleOnCellValueChanged={table.options.meta?.updateData}
                    isCellEditable={isCellEditable}
                    unitType={unitType}
                    reportingType={selectedViewOptions['reporting-types']}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                  />
                );
              }

              return (
                <MemoizedRenderBodyCellColumn
                  alignment={cell.column.columnDef.meta?.alignment}
                  editable={cell.column.columnDef.meta?.editable}
                  type={cell.column.columnDef.meta?.type}
                  key={`row${row.id}col${cell.column.columnDef.id}`}
                  cellValue={cell.getValue<string | undefined>()}
                  fontColor={
                    cell.column.columnDef.id === 'notPlanned'
                      ? getNotPlannedFontColor(cell.getValue<number>())
                      : ''
                  }
                  dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </MemoizedRenderBodyCellColumn>
              );
            })}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ResourceTableGroupedByProjectTable;
