import { WidgetDashboard, IconButtonAnchor, Stack, IWidget } from '@timelog/ui-library';
import FeatureControl from 'src/components/FeatureControl';
import MilestoneFeed from 'src/components/MilestoneFeed';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { NewsFeed } from './components/NewsFeed';
import WidgetFeedback from './components/WidgetFeedback';
import NameArea from './components/NameArea';
import TimeAccuracyWidget from './components/TimeAccuracyWidget';
import BillablePercentageWidget from './components/BillablePercentageWidget';
import ExternalPercentageWidget from './components/ExternalPercentageWidget';

import styles from './Home.module.scss';

export const Home = () => {
  const { isValidIndexControlName: hasMilestonesFeatureAccess } =
    useIsValidIndexControlName('Milestones');

  const iconName = hasMilestonesFeatureAccess ? 'flag' : 'bullhorn';

  const widgetList: Array<IWidget> = [
    {
      key: 'RegistrationAccuracy',
      size: '1',
      actionName: 'GetTimeRegistrationAccuracyInPeriodV2',
      children: <TimeAccuracyWidget />,
    },
    {
      key: 'ExternalPercentage',
      size: '1',
      actionName: 'GetWidgetDataExternalPercentageV2',
      children: <ExternalPercentageWidget />,
    },
    {
      key: 'BillablePercentage',
      size: '1',
      actionName: 'GetWidgetDataBillablePercentageV2',
      children: <BillablePercentageWidget />,
    },
    {
      key: 'Feedback',
      size: '4',
      children: <WidgetFeedback />,
    },
  ];

  return (
    <div className={styles.dashboard}>
      <div className={styles.name}>
        <NameArea />
      </div>
      <div className={styles.mobileSidebar}>
        <IconButtonAnchor
          iconName={iconName}
          iconSize="large"
          tooltipText="Feeds"
          href="#sidebar"
          data-automation-id={`DashboardAnchorToSidebar${iconName[0].toUpperCase()}${iconName.substring(
            1,
          )}`}
        />
      </div>
      <div className={styles.main}>
        <WidgetDashboard widgetList={widgetList} />
      </div>
      <div id="sidebar" className={styles.sidebar} data-automation-id="DashboardSidebar">
        <Stack verticalMargin="xxLarge">
          <FeatureControl indexControlName="Milestones">
            <MilestoneFeed />
          </FeatureControl>
          <FeatureControl indexControlName="Feed">
            <NewsFeed />
          </FeatureControl>
        </Stack>
      </div>
    </div>
  );
};

export default Home;
